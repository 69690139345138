/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({
  description, lang, meta, title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  return (
    <Helmet>
      <title>DieselBank</title>
      <meta name="title" content="Dieselbank - O Novo Banco Digital para você, caminhoneiro" />
      <meta
        name="description"
        content="Dieselbank - Chegou o banco digital para a família caminhoneira. Tenha acesso a cashback, descontos, salas exclusivas e atendimento personalizado."
      />
      <meta
        name="keywords"
        content='<meta name="keywords" content="banco digital, diesel, dieselbank, cashback, dinheiro de volta, banco exclusivo, atendimento personalizado, clube de descontos, cashback, abastecimento, postopix, pix, caminhoneiro, abastecimento, clube de descontos, caminhão">'
      />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="Portuguese" />
      <meta name="revisit-after" content="10 days" />
      <meta name="author" content="Marcel Souza - Dieselbank" />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
